<template>
  <div class="app-container">
    <div class="BulkCrmsCusAddressDetailAdd">
      <el-row>
        <el-col :span="24">
          <el-form ref="addressDetailInfo" :model="addressDetailInfo" :rules="rules" label-width="80px"
                   class="demo-ruleForm">
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="addressDetailInfo.contacts"></el-input>
            </el-form-item>
            <el-form-item label="职务" prop="contactsPost">
              <el-input v-model="addressDetailInfo.contactsPost"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="contactsPhone">
              <el-input v-model="addressDetailInfo.contactsPhone"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="addressDetailed">
              <el-input placeholder="联系人地址" v-model="addressDetailInfo.addressDetailed" :disabled="true">
                <el-button slot="append" icon="el-icon-map" @click="showMap" type="primary">选择</el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <van-button type="primary" size="large" native-type="submit" @click="saveAddress('addressDetailInfo')">保存
      </van-button>
    </div>

    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";

export default {
  name: "BulkCrmsCusAddressDetailAdd",
  components: {BMapAddressSelect},
  data() {
    return {
      // 位置信息
      addressDetailInfo: {
        addressOtherid: '',
        addressId: '',
        contacts: '',
        contactsPost: '',
        contactsPhone: '',
        addressDetailed: '',
        addressLongitude: '',
        addressLatitude: '',
        addressProvince: '',
        addressCity: '',
        district: ''
      },
      rules: {
        contacts: [
          {required: true, message: '请输入联系人名称', trigger: 'blur'},
          {min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur'}
        ],
        contactsPost: [
          {required: true, message: '请输入联系人职务', trigger: 'blur'},
          {min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur'}
        ],
        contactsPhone: [
          {required: true, message: '请输入联系人手机 ', trigger: 'blur'},
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的电话",
            trigger: "blur",
          },
        ],
        addressDetailed: [
          {required: true, message: '请填写详细地址', trigger: 'blur'}
        ]
      },
      searchAddresKeywords: "",
      openMap: false,
    };
  },
  mounted() {
    this.addressDetailInfo.addressId = this.$route.params.name
    console.log(this.addressDetailInfo)
  },
  methods: {
    saveAddress(formName) {
      console.log(this.addressDetailInfo)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax.post('/api/ajax/cusAddress/saveBulkCrmAddresstwo.json',
              {
                addressOtherid: this.$route.params.name,
                contacts: this.addressDetailInfo.contacts,
                contactsPhone: this.addressDetailInfo.contactsPhone,
                contactsPost: this.addressDetailInfo.contactsPost,
                addressProvince: this.addressDetailInfo.addressProvince,
                addressCity: this.addressDetailInfo.addressCity,
                addressDetailed: this.addressDetailInfo.addressDetailed,
                addressLongitude: this.addressDetailInfo.addressLongitude,
                addressLatitude: this.addressDetailInfo.addressLatitude,
                createdPeople: this.$store.getters.userId,
              }).then(res => {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '添加成功',
                type: 'success'
              });
              this.$router.replace({path: '/BulkCrmsCusAddressDetailList/' + this.$route.params.name})
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('添加失败');
          })
        } else {
          console.log('未通过校验!!');
          return false;
        }
      });
    },
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.addressDetailInfo = addressDetailInfo;
    },
  },
}
</script>

<style scoped>
.app-container {
  background: white;
}

.BulkCrmsCusAddressDetailAdd {
  margin: 15px 25px 15px 15px;
  background: white;
}

.detail {
  padding: 5px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>
